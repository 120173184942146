import { Box, Button, Flex, Link, Show } from "@chakra-ui/react";

import { trackEvent } from "dapp-sdk-v2";

import { StrapiSectionDetail } from "@customtypes/global";
import { IIndexable } from "@customtypes/common";

import { AUDIT_CLICK } from "@constants/analytics";

import Section from "../Section";
import BaseImage from "dapp-sdk-v2/components/Image";

interface AuditsProps {
  sectionDetails: StrapiSectionDetail;
  imgKey: string;
}

const getAuditsIllustration: IIndexable = {
  ethSecurity: "/assets/images/security/security_eth.webp",
  bnbSecurity: "/assets/images/security/security_bnb.webp",
  polygonSecurity: "/assets/images/security/security_polygon.webp",
};

const Audits = ({ sectionDetails, imgKey }: AuditsProps) => {
  return (
    <Section
      title={sectionDetails.title}
      subTitle={sectionDetails.sub_title}
      id={sectionDetails.section_id}
    >
      {/* TODO create button variant */}
      {sectionDetails.cta_text && (
        <Link href={sectionDetails.cta_link} isExternal>
          <Button
            mt={{ base: "24px", lg: "40px" }}
            bg="white"
            w="202px"
            h={{ base: "40px", lg: "58px" }}
            border="1px solid"
            borderColor="seperator"
            color="black"
            _hover={{
              bg: "primary",
              color: "white",
            }}
            onClick={() => {
              trackEvent(AUDIT_CLICK);
            }}
          >
            {sectionDetails.cta_text}
          </Button>
        </Link>
      )}
      <Box pt={{ base: "64px", lg: "80px" }}>
        <Flex px={4} justify="center">
          <Box h={{ base: 200, lg: 449 }} overflow="hidden" maxW="736px">
            <Show above="lg">
              <BaseImage
                src={getAuditsIllustration[imgKey]}
                alt="security"
                width={736}
                height={733}
              />
            </Show>
            <Show below="lg">
              <BaseImage
                src={getAuditsIllustration[imgKey]}
                alt="security"
                width={295}
                height={294}
              />
            </Show>
          </Box>
        </Flex>
      </Box>
    </Section>
  );
};

export default Audits;
